<template lang="pug">
header
  router-link(:to="{ name: 'home' }" @click="getQuote")
    span random
    svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="{ loading: loading }")
      path(d="M12 6v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.2.2-.51 0-.71l-2.79-2.79c-.31-.31-.85-.09-.85.36V4c-4.42 0-8 3.58-8 8 0 1.04.2 2.04.57 2.95.27.67 1.13.85 1.64.34.27-.27.38-.68.23-1.04C6.15 13.56 6 12.79 6 12c0-3.31 2.69-6 6-6zm5.79 2.71c-.27.27-.38.69-.23 1.04.28.7.44 1.46.44 2.25 0 3.31-2.69 6-6 6v-1.79c0-.45-.54-.67-.85-.35l-2.79 2.79c-.2.2-.2.51 0 .71l2.79 2.79c.31.31.85.09.85-.35V20c4.42 0 8-3.58 8-8 0-1.04-.2-2.04-.57-2.95-.27-.67-1.13-.85-1.64-.34z")
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Header',
  methods: {
    ...mapActions(['getRandomQuote']),
    getQuote() {
      if (this.$route.name === 'home') this.getRandomQuote();
    },
  },
  computed: {
    ...mapState(['loading']),
  },
};
</script>
